<template>
  <div id="app">
    <div id="header" class="flex flex-row flex-wrap">
      <img class="logo sm:mx-0 mx-auto sm:w-60 w-36 cursor-pointer" src="@/assets/logo.png" @click="goHome"/>
      <div class="operation text-lg mx-auto flex flex-wrap justify-center">
        <span class="text-xs sm:text-sm" @click="goContact">
          {{ $t("h.Nav.ContactUs") }}
        </span>
        <span class="text-xs sm:text-sm" @click="changeLang('en')">English</span>
        <span class="text-xs sm:text-sm" @click="changeLang('ar')">بالعربية</span>
        <span class="text-xs sm:text-sm" @click="changeLang('ru')">Русский язык</span>
        <span class="text-xs sm:text-sm" @click="changeLang('fa')">فارسی</span>
      </div>
      <img alt="Facebook" class="w-6 sm:mr-auto" src="@/assets/Facebook_logo_36x36.svg.png" @click="goFacebook">
    </div>
    <router-view/>
    <div id="footer" class="w-full p-4 flex flex-col justify-center items-center gap-3">
      <div class="title">{{ $t("h.Footer.Title") }}</div>
      <div class="subheading">{{ $t("h.Footer.Subheading") }}</div>
      <div class="button cursor-pointer" @click="goContact">{{ $t("h.Footer.BtnText") }}</div>
    </div>
  </div>
</template>
<script>
import utils from "@/utils/utils";

export default {
  name: 'App',
  data() {
    return {}
  },
  methods: {
    changeLang(opt) {
      utils.setLocalLangKey(opt)
      this.$i18n.locale = opt
      window.location.reload()
    },
    goHome() {
      this.$router.push({path: '/'})
    },
    goContact() {
      this.$router.push({path: '/contact'})
    },
    goFacebook() {
      window.open("https://www.facebook.com/share/1D2rt8EaNy/")
    }
  },
  created() {
  },
  mounted() {
  }
}
</script>
<style lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: sans-serif;
}

//*::-webkit-scrollbar {
//  display: none;
//}

#main img {
  //aspect-ratio: 4/3;
}

#app {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-height: 100vh;
  background-color: #f0f0f0;
}

#header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: white;

  .logo {
    //width: 25%;
  }

  .operation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

#footer {
  text-align: center;

  .title {
    font-size: 24px;
    color: #2A2A2A;
  }

  .subheading {
    font-size: 16px;
  }

  .button {
    color: #ffffff;
    background-color: #F90;
    font-size: 14px;
    padding: 14px 20px;
    border-radius: 5px;
  }
}

</style>